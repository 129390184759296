import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Checkbox,
  FormControlLabel,
  Link,
  Snackbar,
} from "@mui/material";
import { authenticateUser } from "../utils/identityManager"; // Import authenticateUser

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleLogin = () => {
    if (authenticateUser(username, password)) {
      onLogin(); // Trigger login function passed as prop
    } else {
      setShowToast(true); // Show toast if authentication fails
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Left Side - Image and Heading */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Typography variant="h3" sx={{ mb: 3, textAlign: "center" }}>
          Track your equipment in real time
        </Typography>
        <img
          src="/login_logo.png" // Use your desired image URL
          alt="Equipment Tracking"
          style={{
            width: "100%",
            maxWidth: "400px",
            height: "auto",
          }}
        />
      </Box>

      {/* Right Side - Login Form */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            maxWidth: "400px",
            padding: "20px",
            boxShadow: 3,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, textAlign: "left" }}>
            Welcome to Nayaati
          </Typography>
          <Typography variant="body2" sx={{ mb: 4, textAlign: "left" }}>
            Enter your email and password to access your account.
          </Typography>

          {/* Username and Password Fields */}
          <Typography sx={{ textAlign: "left" }}>Username</Typography>
          <TextField
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Typography sx={{ textAlign: "left" }}>Password</Typography>
          <TextField
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mb: 3 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {/* Remember Me Checkbox */}
          <FormControlLabel
            control={<Checkbox />}
            label="Remember Me"
            sx={{
              mb: 3,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          />

          {/* Login Button */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            sx={{
              mb: 2,
              padding: "10px",
              backgroundColor: "#3644BE",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
            }}
          >
            Login
          </Button>

          {/* Forgot Password and Signup Links */}
          <Box sx={{ textAlign: "center" }}>
            <Link href="#" sx={{ display: "block", mb: 1 }}>
              Forgot password?
            </Link>
            <Typography variant="body2">
              Don't have an account?{" "}
              <Link href="#" sx={{ fontWeight: "bold" }}>
                Sign up
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Box>

      {/* Toast Notification for Failed Login */}
      <Snackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        message="Invalid username or password"
        autoHideDuration={3000} // Hide after 3 seconds
      />
    </Box>
  );
};

export default Login;
