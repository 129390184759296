// src/utils/identityManager.js

export const users = [
  { username: "suraj.suddala@ncompasbusiness.com", password: "password" }, // Example user
  // Add more users here as needed
];

export const authenticateUser = (username, password) => {
  const user = users.find(
    (user) => user.username === username && user.password === password
  );
  return user ? true : false; // Return true if authenticated, false if not
};
