import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./layouts/layout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track login state
  const userName = "Suddala Suraj";

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Route for the Login Page */}
          <Route
            path="/"
            element={
              !isAuthenticated ? (
                <Login onLogin={() => setIsAuthenticated(true)} />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            }
          />

          {/* Route for the Dashboard Page */}
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <Layout userName={userName}>
                  <Dashboard userName={userName} />
                </Layout>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />

          {/* Redirect any other routes to the login page */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
