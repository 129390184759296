import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  MenuItem,
} from "@mui/material";

const AccountDetailsForm = ({ handleNext, handleBack }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [region, setRegion] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [giveConsent, setGiveConsent] = useState(false);

  const handleRegister = () => {
    if (username && password && confirmPassword && acceptTerms) {
      handleNext();
    } else {
      // Add validation or error handling here
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "18px", fontWeight: "bold" }}>
        Set Up Your Vendor Account
      </Typography>
      <Typography variant="body2" sx={{ mb: 3, fontSize: "14px", color: "#6B7280" }}>
        Create a secure account to manage your profile and opportunities on Nayaati.
      </Typography>

      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      {/* <TextField
        label="Region of Operation"
        select
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={region}
        onChange={(e) => setRegion(e.target.value)}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      >
        <MenuItem value="Region 1">Region 1</MenuItem>
        <MenuItem value="Region 2">Region 2</MenuItem>
        <MenuItem value="Region 3">Region 3</MenuItem>
      </TextField> */}

      <TextField
        label="Confirm Password"
        type="password"
        variant="outlined"
        fullWidth
        sx={{ mb: 4 }}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
              sx={{ padding: 0 }} // Adjust checkbox padding
            />
          }
          label={
            <Typography sx={{ fontSize: "12px", color: "#6B7280", lineHeight: "1.2" }}>
              I acknowledge and accept the Vendor{" "}
              <Link href="#" underline="hover">
                Terms and Conditions
              </Link>
              .
            </Typography>
          }
          sx={{ alignItems: "flex-start", margin: 0 }} // Align checkbox and label
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={giveConsent}
              onChange={(e) => setGiveConsent(e.target.checked)}
              sx={{ padding: 0 }} // Adjust checkbox padding
            />
          }
          label={
            <Typography sx={{ fontSize: "12px", color: "#6B7280", lineHeight: "1.2" }}>
              I consent to Nayaati contacting me about vendor opportunities and related updates.
            </Typography>
          }
          sx={{ alignItems: "flex-start", margin: 0 }} // Align checkbox and label
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, mt: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            textTransform: "none",
          }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            backgroundColor: "#3644BE",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={handleRegister}
        >
          Register
        </Button>
      </Box>
    </Box>
  );
};

export default AccountDetailsForm;
