import React, { useState } from "react";
import { Box, Button, Paper, Typography, AppBar, Toolbar, Link } from "@mui/material";
import StepperComponent from "../components/common/StepperComponents";
import BasicDetailsForm from "../components/common/BasicDetails";
import CompanyDetailsForm from "../components/common/CompanyDetailsForm";
import AccountDetailsForm from "../components/common/AccountDetailsForm";
import { Link as RouterLink } from "react-router-dom";

const steps = ["Basic Details", "Company Details", "Account Details"];

const Registration = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
    <Box
      sx={{
        backgroundImage: "url('/BackgroundSmall.png')", // Replace with actual path
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "95vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      {/* AppBar Section */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: "0 20px",
            height: "60px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo.png"
              alt="Logo"
              style={{
                height: "40px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <Typography
              fontSize="16px"
              sx={{ color: "#7A7C82", fontWeight: "bold" }}
            >
              Need Help?
            </Typography>
            <Link
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#3644BE",
                  borderColor: "#3644BE",
                  borderRadius: "8px",
                  textTransform: "none",
                  padding: "5px 16px", // Adjusted padding
                  marginRight: "20px", // Added right margin for alignment
                  "&:hover": {
                    borderColor: "#1565c0",
                    color: "#1565c0",
                  },
                }}
              >
                Login
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      <Box sx={{ textAlign: "center", marginTop: "50px", 
        // marginBottom: "24px" 
        }}>
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "28.8px",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
          }}
        >
          Let's get you started!
        </Typography>

        <Typography
          sx={{
            fontFamily: "Nunito Sans",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "21.82px",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
            // marginBottom: "24px",
          }}
        >
          Sign up to unlock real-time insights, manage your heavy machinery, and
          streamline decision-making with ease.
        </Typography>
        <StepperComponent steps={steps} activeStep={activeStep} />
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          backgroundColor: "#f8f9fb",
          borderRadius: "8px",
          boxShadow: 3,
          padding: "5px 30px 15px 30px",
        }}
      >
        {/* Conditional Form Rendering */}
        {activeStep === 0 && <BasicDetailsForm handleNext={handleNext} />}
        {activeStep === 1 && <CompanyDetailsForm handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 2 && <AccountDetailsForm handleNext={handleNext} handleBack={handleBack}/>}

      </Box>
    </Box>
      <Box
      component="footer"
      sx={{
        textAlign: "center",
        backgroundColor: "#F9F9F9",
        padding: "10px", 
        borderTop: "1px solid #E0E0E0",
      }}
    >
      <Typography variant="body2" sx={{ fontSize: "12px", color: "#7A7C82" }}>
        © 2024 Nayaati. All Rights Reserved. Powered by Nayaati.
      </Typography>
    </Box>
    </>
  );
};

export default Registration;