import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";

const StepperComponent = ({ steps, activeStep }) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel 
    sx={{
        "& .css-3hpdci-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
          color: "#0B9B11",
        },
        "& .css-3hpdci-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":{
            color:"#3644BE"
        },
        mb: 2
     }}
    >
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;