import React from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BasicDetailsForm = ({ handleNext }) => {
  return (
    <Box
      sx={{
        mt: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        mx: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 0, fontSize: "18px", fontWeight: "bold", mt: 0 }} 
      >
        Tell Us About You
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 1, fontSize: "14px", color: "#6B7280" }} 
      >
        Provide your basic details to get started with Nayaati's vendor platform.
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
          mb: 2,
        }}
      >
        <TextField
          label="First Name"
          variant="outlined"
          fullWidth
          InputProps={{
            sx: { height: "36px" }, 
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" }, 
          }}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          fullWidth
          InputProps={{
            sx: { height: "36px" }, 
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
          }}
        />
      </Box>

      <TextField
        label="Email ID"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          sx: { height: "36px" }, 
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />
      <TextField
        label="Phone Number"
        variant="outlined"
        fullWidth
        sx={{ mb: 4 }}
        InputProps={{
          sx: { height: "36px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleNext}
        sx={{
          padding: "4px", 
          backgroundColor: "#3644BE",
          fontSize: "14px", 
          minHeight: "36px", 
          "&:hover": {
            backgroundColor: "#1565c0",
          },
        }}
        endIcon={<ArrowForwardIcon />}
      >
        Next
      </Button>
    </Box>
  );
};

export default BasicDetailsForm;