import React from "react";
import { Box, Button, TextField, Typography, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CompanyDetailsForm = ({ handleNext, handleBack }) => {
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1, fontSize: "18px", fontWeight: "bold" }}>
        Your Business, Your Identity
      </Typography>
      <Typography variant="body2" sx={{ mb: 3, fontSize: "14px", color: "#6B7280" }}>
        Share key details about your business to help us understand your services and expertise.
      </Typography>

      <TextField
        label="Company Name"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Company Email ID"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Region of Operation"
        select
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      >
        <MenuItem value="Region 1">Region 1</MenuItem>
        <MenuItem value="Region 2">Region 2</MenuItem>
        <MenuItem value="Region 3">Region 3</MenuItem>
      </TextField>

      <TextField
        label="Address Field 1"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <TextField
        label="Address Field 2"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        InputProps={{
          sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
        }}
        InputLabelProps={{
          sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
        }}
      />

      <Box
        sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", sm: "row" }, mb: 4 }}
      >
        <TextField
          label="PIN Code"
          variant="outlined"
          fullWidth
          InputProps={{
            sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
          }}
        />
        <TextField
          label="State"
          select
          variant="outlined"
          fullWidth
          InputProps={{
            sx: { height: "36px", padding: "0 10px", fontSize: "14px" },
          }}
          InputLabelProps={{
            sx: { fontSize: "12px", top: "50%", transform: "translateY(-50%)", paddingLeft: "8px" },
          }}
        >
          <MenuItem value="State 1">State 1</MenuItem>
          <MenuItem value="State 2">State 2</MenuItem>
          <MenuItem value="State 3">State 3</MenuItem>
        </TextField>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, mt: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            textTransform: "none",
          }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            flex: 1,
            padding: "4px",
            fontSize: "14px",
            backgroundColor: "#3644BE",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={handleNext}
          endIcon={<ArrowForwardIcon />}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyDetailsForm;