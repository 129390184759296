import { Box } from "@mui/material";
import styled from "styled-components";
import { alpha } from "@mui/material/styles";

export const SeverityInfoChip = styled(Box)(({ color }) => ({
  border: "1.5px solid",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  width: "fit-content",
  padding: "3px 7px 2px 7px",
  backgroundColor: alpha(color, 0.1),
}));
