import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Checkbox,
  FormControlLabel,
  Link,
  Snackbar,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Login = ({ onLogin, userName = "Guest" }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogin = () => {
    if (username && password) {
      onLogin(); // Trigger login function passed as prop
    } else {
      setShowToast(true); // Show toast if authentication fails
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundImage: "url('/BackgroundSmall.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        overflow: "hidden", // Prevents scrolling
      }}
    >
      {/* AppBar Section */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: "0 20px",
            height: "60px", // Reduced height
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo.png"
              alt="Logo"
              style={{
                height: "40px", // Reduced logo height
                marginRight: "20px",
                marginLeft: "20px",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <Typography
              fontSize="16px"
              sx={{ color: "#7A7C82", fontWeight: "bold" }}
            >
              Need Help?
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      <Box
        sx={{
          flexGrow: 1, // Ensures the content section takes up the available space
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          paddingTop: "80px", // Adjusted for reduced AppBar height
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px", // Reduced padding
          }}
        >
          <Typography sx={{ mb: 2, textAlign: "center", fontSize: "28px" }}>
            Track your equipment in real time
          </Typography>
          <img
            src="/login_logo.png"
            alt="Equipment Tracking"
            style={{
              width: "80%", // Reduced width
              maxWidth: "300px", // Reduced max width
              height: "auto",
            }}
          />
        </Box>

        {/* Form Section */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            padding: "10px", // Reduced padding
          }}
        >
          <Paper
            sx={{
              width: "100%",
              maxWidth: "350px", // Reduced max width of the form
              padding: "15px", // Reduced padding inside the Paper
              boxShadow: "0px 0px 16px 0px #9BA3B440",
            }}
          >
            <Typography variant="h6" sx={{ mb: 1, textAlign: "left", fontSize: "18px" }}>
              Welcome to Nayaati
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, textAlign: "left", fontSize: "14px" }}>
              Enter your email and password to access your account.
            </Typography>

            <Typography sx={{ textAlign: "left", mb: 0.5, fontSize: "14px" }}>Username</Typography>
            <TextField
              variant="outlined"
              fullWidth
              sx={{
                mb: 1,
                "& .MuiOutlinedInput-root": {
                  height: 32, // Reduced height
                },
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <Typography sx={{ textAlign: "left", mb: 0.5, fontSize: "14px" }}>Password</Typography>
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              sx={{
                mb: 1.5,
                "& .MuiOutlinedInput-root": {
                  height: 32, // Reduced height
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <FormControlLabel
              control={<Checkbox />}
              label="Remember Me"
              sx={{
                mb: 1.5,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "14px", // Reduced font size
              }}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleLogin}
              sx={{
                mb: 1.5,
                padding: "4px",
                minHeight: "32px", // Reduced button height
                fontSize: "14px", // Reduced font size
                backgroundColor: "#3644BE",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Login
            </Button>

            <Box sx={{ textAlign: "center" }}>
              <Link
                component={RouterLink}
                to="/password-reset"
                sx={{ display: "block", mb: 0.5, fontSize: "14px" }}
              >
                Forgot password?
              </Link>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                Don't have an account?{" "}
                <Link
                  component={RouterLink}
                  to="/register"
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  Sign up
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Footer Section */}
      <Box
        component="footer"
        sx={{
          textAlign: "center",
          backgroundColor: "#F9F9F9",
          padding: "10px", // Reduced padding for smaller height
          borderTop: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="body2" sx={{ fontSize: "12px", color: "#7A7C82" }}>
          © 2024 Nayaati. All Rights Reserved. Powered by Nayaati.
        </Typography>
      </Box>

      <Snackbar
        open={showToast}
        onClose={() => setShowToast(false)}
        message="Invalid username or password"
        autoHideDuration={3000}
      />
    </Box>
  );
};

export default Login;