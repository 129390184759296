import React from "react";
import { Button, Typography, Box, Paper, AppBar, Toolbar, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const PasswordResetForm = () => {
  return (
    <Box
      sx={{
        backgroundImage: "url('../BackgroundSmall.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: "0 20px",
            height: "60px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo.png"
              alt="Logo"
              style={{
                height: "40px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <Typography
              fontSize="16px"
              sx={{ color: "#7A7C82", fontWeight: "bold" }}
            >
              Need Help?
            </Typography>
            <Link
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#3644BE",
                  borderColor: "#3644BE",
                  borderRadius: "8px",
                  textTransform: "none",
                  padding: "5px 16px", // Adjusted padding
                  marginRight: "20px", // Added right margin for alignment
                  "&:hover": {
                    borderColor: "#1565c0",
                    color: "#1565c0",
                  },
                }}
              >
                Login
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          marginTop: "80px",
        }}
      >
        {/* Form Section */}
        <Paper
          sx={{
            width: "100%",
            maxWidth: "400px", 
            padding: "16px 24px",
            boxShadow: "0px 0px 16px 0px #9BA3B440",
            textAlign: "center",
          }}
        >
          <img
            src="../passwordresentimage.svg"
            alt="Password Reset"
            style={{
              width: "120px", // Reduced image size
              marginBottom: "16px",
              marginTop: "30px", // Adjusted top margin
            }}
          />
          <Typography
            variant="h6" // Reduced text size
            component="h3"
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Password Reset Email Sent!
          </Typography>
          <Typography sx={{ color: "#6B7280", mb: 3, fontSize: "14px" }}> {/* Reduced font size */}
            We’ve sent a password reset link to your registered email address.
            Please check your inbox and follow the instructions to reset your
            password. If you don’t see the email, check your spam folder or
            click the button below to resend.
          </Typography>
          <Box sx={{ display: "flex", gap: 1.5, justifyContent: "center", width: "100%" }}>
  <Link
    component={RouterLink}
    to="/"
    sx={{
      textDecoration: "none",
      flexGrow: 1, // Ensure buttons are equally sized
    }}
  >
    <Button
      variant="outlined"
      sx={{
        width: "100%",
        fontSize: "12px", // Reduced font size
        padding: "4px 8px", // Reduced padding
        color: "#3644BE", // Text color
        borderColor: "#3644BE", // Border color
        "&:hover": {
          color: "#1565c0", // Hover text color
          borderColor: "#1565c0", // Hover border color
        },
      }}
    >
      Back to Login
    </Button>
  </Link>
  <Button
    variant="contained"
    sx={{
      flexGrow: 1,
      background: "#3644BE",
      color: "#FFFFFF",
      fontSize: "12px", // Reduced font size
      padding: "4px 8px", // Reduced padding
      "&:hover": {
        background: "#1565c0",
      },
    }}
  >
    Resend Mail
  </Button>
</Box>

        </Paper>

      </Box>

      <Box
        component="footer"
        sx={{
          textAlign: "center",
          backgroundColor: "#F9F9F9",
          padding: "10px", 
          borderTop: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="body2" sx={{ fontSize: "12px", color: "#7A7C82" }}>
          © 2024 Nayaati. All Rights Reserved. Powered by Nayaati.
        </Typography>
      </Box>
    </Box>
  );
};

export default PasswordResetForm;