import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import {
  SearchIcon,
  Notifications,
  Dashboard,
  MyEquipments,
  MyIotDevices,
  ManageRentals,
  IotLogBook,
  Invoice,
  MyAccount,
  Settings,
  ContactUs,
} from "../Icons/Icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../App.css";

const Layout = ({ userName, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const drawerWidth = 260;

  const drawerItems = [
    { label: "Dashboard", to: "/", icon: <Dashboard /> },
    { label: "My Equipments", to: "/my-equipments", icon: <MyEquipments /> },
    { label: "My IoT Devices", to: "/my-iot-devices", icon: <MyIotDevices /> },
    { label: "Manage Rentals", to: "/my-rentals", icon: <ManageRentals /> },
    { label: "IoT Log Book", to: "/iot-log-book", icon: <IotLogBook /> },
    { label: "Invoice", to: "/invoice", icon: <Invoice /> },
  ];

  const bottomDrawerItems = [
    { label: "My Account", to: "/my-account", icon: <MyAccount /> },
    { label: "Settings", to: "/settings", icon: <Settings /> },
    { label: "Contact Us", to: "/contact-us", icon: <ContactUs /> },
  ];

  const drawer = (
    <Box
      sx={{
        position: "relative",
        mt: 2,
        color: "white",
        width: drawerWidth,
        height: "calc(100vh - 80px)", // Ensures the drawer takes the full screen height excluding AppBar
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List>
        {drawerItems.map((item) => (
          <ListItem
            button
            key={item.label}
            sx={{
              margin: 1,
              justifyContent: "flex-start",
              display: "flex",
              color: "white",
              borderRadius: "10px",
              paddingLeft: 2,
              paddingRight: 4,
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#000000",
                marginLeft: "2px",
                marginRight: "2px",
              },
              "& .MuiListItemIcon-root": {
                minWidth: "40px",
                color: "white",
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ fontWeight: 700 }}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ marginTop: "auto" }}>
        <List>
          {bottomDrawerItems.map((item) => (
            <ListItem
              button
              key={item.label}
              sx={{
                margin: 1,
                justifyContent: "flex-start",
                display: "flex",
                color: "white",
                borderRadius: "10px",
                paddingLeft: 2,
                paddingRight: 4,
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                  marginLeft: "2px",
                  marginRight: "2px",
                },
                "& .MuiListItemIcon-root": {
                  minWidth: "40px",
                  color: "white",
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ fontWeight: 700 }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", bgcolor: "#F5F6F7", minHeight: "100vh" }}>
      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: "0 20px",
            height: "80px",
          }}
        >
          {/* Logo on the left */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src="/logo.png" // Add your logo path here
              alt="Logo"
              style={{
                height: "50px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            />
          </Box>

          {/* User profile image and name */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <IconButton edge="end">
                <SearchIcon />
              </IconButton>
              <IconButton edge="end">
                <Notifications />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Avatar
                sx={{ width: 40, height: 40 }}
                alt="User Name"
                src="/avatar.png" // Add your profile image path here
              />
              <Typography
                fontSize="18px"
                sx={{ color: "#7A7C82", fontWeight: "bold" }}
              >
                {userName}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for larger screens */}
      {!isMobile && (
        <Drawer
          sx={{
            flexShrink: 0,
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "black",
              marginTop: "80px", // Ensures the drawer is below the AppBar
              transition: "width 0.3s",
            },
          }}
          variant="permanent"
          anchor="left"
          open
        >
          {drawer}
        </Drawer>
      )}

      {/* Bottom Drawer for mobile */}
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            height: "70px",
            backgroundColor: "black",
            zIndex: theme.zIndex.drawer + 1,
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "10px",
            paddingLeft: "5px",
            paddingBottom: "8px",
            paddingRight: "10px",
          }}
        >
          {drawerItems.map((item) => (
            <Box
              key={item.label}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                color: "white",
                textDecoration: "none",
                padding: "5px 0",
                flexGrow: 1,
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#000000",
                },
              }}
            >
              {item.icon}
              {/* <Typography
                variant="caption"
                sx={{ color: "white", marginTop: "4px", paddingLeft: "10px" }}
              >
                {item.label}
              </Typography> */}
            </Box>
          ))}
        </Box>
      )}

      {/* Content Section (Main area) */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginTop: "80px", // Offset content below AppBar
          paddingLeft: { sm: "260px", xs: 0 },
          paddingTop: "20px",
        }}
      >
        {/* AppRoutes is rendered here */}
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
